/* eslint eslint-comments/no-use: off */

import './include-fragment-element-hacks'
import IncludeFragmentElement from '@github/include-fragment-element'
import {controller, target, attr} from '@github/catalyst'

const DEFAULT_INTERVAL = 1000
const DEFAULT_MULTIPLIER = 1.5

@controller
class PollIncludeFragmentElement extends IncludeFragmentElement {
  @target retryButton: HTMLButtonElement | null
  @attr intervalMilliseconds = DEFAULT_INTERVAL
  @attr backoffMultiplier = DEFAULT_MULTIPLIER

  pollingTimeout: NodeJS.Timeout | null = null

  override async fetch(request: Request, ms?: number): Promise<Response> {
    const response = await super.fetch(request)
    // Protecting from NaN here as per the documentation describes that it will be
    // return 0 if attribute doesn't exist: https://catalyst.rocks/guide/attrs/

    let interval = ms || this.intervalMilliseconds
    if (!interval || isNaN(interval)) {
      interval = DEFAULT_INTERVAL
    }
    const multiplier = isNaN(this.backoffMultiplier) ? DEFAULT_MULTIPLIER : this.backoffMultiplier

    if (response.status === 202) {
      await new Promise(resolve => {
        this.pollingTimeout = setTimeout(resolve, interval)
      })
      return this.fetch(request, interval * multiplier)
    } else {
      return response
    }
  }

  override refetch() {
    this.cancelPolling()
    return super.refetch()
  }

  override connectedCallback() {
    super.connectedCallback()

    if (this.retryButton) {
      this.retryButton.addEventListener('click', () => {
        this.refetch()
      })
    }
  }

  disconnectedCallback() {
    this.cancelPolling()
  }

  private cancelPolling() {
    if (this.pollingTimeout) {
      clearTimeout(this.pollingTimeout)
    }
  }
}
